import { box } from 'ngrx-forms';
import { ServiceStackTypes } from '@zerops/models/service-stack';

export const FEATURE_NAME = '@zerops/zerops/core/service-stack-base';

export enum ServiceStackOperationTypes {
  Stop = 'stop',
  Start = 'start',
  Restart = 'restart',
  Reload = 'reload'
}

export const SERVICE_STACK_DEFAULT_VALUES = {
  [ServiceStackTypes.Elasticsearch]: {
    elasticsearch_v6: {
    },
    elasticsearch_v7: {
    }
  },
  [ServiceStackTypes.Redis]: {
    keydb_v6: {
    }
  },
  [ServiceStackTypes.Postgres]: {
    postgresql_v12: {
    },
    postgresql_v14: {
    },
    postgresql_v16: {
    }
  },
  [ServiceStackTypes.Valkey]: {
    valkey_v7_2: {
    }
  },
  [ServiceStackTypes.Qdrant]: {
    qdrant_v1_10: {
    }
  },
  [ServiceStackTypes.Nats]: {
    nats_v2_10: {
    }
  },
  [ServiceStackTypes.Kafka]: {
    kafka_v3_8: {
    }
  },
  [ServiceStackTypes.RabbitMq]: {
    rabbitmq_v3: {
    }
  },
  [ServiceStackTypes.MariaDb]: {
    mariadb_v10_3: {
    },
    mariadb_v10_4: {
    },
    mariadb_v10_6: {
    }
  },
  [ServiceStackTypes.MongoDb]: {
    mongodb_v4: {
    }
  },
  [ServiceStackTypes.PhpNginx]: {
    php_nginx_v7_3: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v7_4_nginx_v1_20: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v7_4_nginx_v1_22: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_0_nginx_v1_20: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_0_nginx_v1_22: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_1_nginx_v1_20: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_1_nginx_v1_22: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_2_nginx_v1_22: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_3_nginx_v1_22: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    }
  },
  [ServiceStackTypes.PhpApache]: {
    php_apache_v7_3: {
      mode: 'HA',
      startWithoutCode: false,
      documentRoot: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v7_4_apache_v2_4: {
      mode: 'HA',
      startWithoutCode: false,
      documentRoot: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_0_apache_v2_4: {
      mode: 'HA',
      startWithoutCode: false,
      documentRoot: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_1_apache_v2_4: {
      mode: 'HA',
      startWithoutCode: false,
      documentRoot: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_2_apache_v2_4: {
      mode: 'HA',
      startWithoutCode: false,
      documentRoot: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    php_v8_3_apache_v2_4: {
      mode: 'HA',
      startWithoutCode: false,
      documentRoot: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    }
  },
  [ServiceStackTypes.Nginx]: {
    nginx_v1_16: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      }
    },
    nginx_v1_18: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      }
    },
    nginx_v1_20: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      }
    },
    nginx_v1_22: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      }
    },
    nginx_v1_24: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      }
    }
  },
  [ServiceStackTypes.NodeJs]: {
    nodejs_v10: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    nodejs_v12: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: [],
    },
    nodejs_v13: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    nodejs_v14: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    nodejs_v16: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    nodejs_v18: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    nodejs_v20: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    nodejs_v22: {
      mode: 'HA',
      startWithoutCode: false,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n'
    }
  },
  [ServiceStackTypes.Ubuntu]: {
    ubuntu_v22: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    }
  },
  [ServiceStackTypes.Alpine]: {
    alpine_v3_17: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    alpine_v3_18: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    alpine_v3_19: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    alpine_v3_20: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    }
  },
  [ServiceStackTypes.Golang]: {
    golang_v1: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    }
  },
  [ServiceStackTypes.ObjectStorage]: {
    object_storage_v1: {
      diskGBytes: 2,
      policy: 'public-read',
      rawPolicy: `{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Principal": {
        "AWS": [
          "*"
        ]
      },
      "Action": [
        "s3:GetBucketLocation",
        "s3:ListBucket"
      ],
      "Resource": [
        "arn:aws:s3:::{{.BucketName}}"
      ]
    },
    {
      "Effect": "Allow",
      "Principal": {
        "AWS": [
          "*"
        ]
      },
      "Action": [
        "s3:GetObject"
      ],
      "Resource": [
        "arn:aws:s3:::{{.BucketName}}/*"
      ]
    }
  ]
}`
    }
  },
  [ServiceStackTypes.SharedStorage]: {
    shared_storage_v1: {
      storageStackIds: box([])
    },
    moosefs_v3: {
      storageStackIds: box([])
    }
  },
  [ServiceStackTypes.DotNet]: {
    dotnet_v6: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    dotnet_v7: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    dotnet_v8: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    }
  },
  [ServiceStackTypes.Rust]: {
    rust_v1: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    rust_v1_78: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    rust_v1_80: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    rust_nightly: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    }
  },
  [ServiceStackTypes.Python]: {
    python_v3_8: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: [],
    },
    python_v3_9: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    python_v3_10: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    python_v3_11: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    },
    python_v3_12: {
      mode: 'HA',
      startWithoutCode: false,
      startCommand: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      initCommands: []
    }
  },
  [ServiceStackTypes.Static]: {
    static: {
      mode: 'HA',
      startWithoutCode: false,
      nginxConfig: undefined,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
      gitHubIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      },
      gitLabIntegration: {
        repositoryFullName: undefined,
        isActive: true,
        eventType: 'TAG',
        branchName: undefined,
        triggerBuild: false
      }
    }
  },
  [ServiceStackTypes.Bun]: {
    bun_v1_1: {
      mode: 'HA',
      startWithoutCode: false,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
    }
  },
  [ServiceStackTypes.Deno]: {
    deno_v1: {
      mode: 'HA',
      startWithoutCode: false,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
    }
  },
  [ServiceStackTypes.Java]: {
    java_v17: {
      mode: 'HA',
      startWithoutCode: false,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
    },
    java_v21: {
      mode: 'HA',
      startWithoutCode: false,
      userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n',
    }
  }
};

// TODO: `postgres` temp
export const FORBIDDEN_STACK_IDS = [ 'contabo_ssh', 'build', 'runtime' ];

export const FORBIDDEN_STACK_CATEGORIES = [ 'BUILD', 'PREPARE_RUNTIME' ];

export const BUILD_STACK_CATEGORY = 'BUILD';

export const NOT_IMPORTANT_STACK_IDS = [
  ServiceStackTypes.SharedStorage,
  ServiceStackTypes.ObjectStorage,
  ServiceStackTypes.PhpApache
];

export enum ServiceStackTextCategories {
  Database = 'database',
  Messaging = 'messaging',
  PhpWebserver = 'phpWebserver',
  Webserver = 'webserver',
  Static = 'static',
  RuntimeEnvironment = 'runtimeEnvironment',
  ProgrammingLanguage = 'programmingLanguage',
  SearchEngine = 'searchEngine',
  Storage = 'storage',
  ObjectStorage = 'objectstorage',
  LinuxContainer = 'linuxContainer'
}

export const SERVICE_STACK_PRIORITY_MAP = {
  [ServiceStackTypes.Static]: 0,
  [ServiceStackTypes.Nginx]: 1,
  [ServiceStackTypes.Ubuntu]: 2,
  [ServiceStackTypes.Alpine]: 3,
  [ServiceStackTypes.NodeJs]: 4,
  [ServiceStackTypes.Bun]: 5,
  [ServiceStackTypes.Deno]: 6,
  [ServiceStackTypes.Golang]: 7,
  [ServiceStackTypes.Rust]: 8,
  [ServiceStackTypes.Python]: 9,
  [ServiceStackTypes.DotNet]: 10,
  [ServiceStackTypes.Java]: 11,
  [ServiceStackTypes.PhpApache]: 12,
  [ServiceStackTypes.PhpNginx]: 13,
  [ServiceStackTypes.MariaDb]: 14,
  [ServiceStackTypes.MongoDb]: 15,
  [ServiceStackTypes.Postgres]: 16,
  [ServiceStackTypes.Redis]: 17,
  [ServiceStackTypes.Valkey]: 18,
  [ServiceStackTypes.Qdrant]: 19,
  [ServiceStackTypes.Elasticsearch]: 20,
  [ServiceStackTypes.RabbitMq]: 21,
  [ServiceStackTypes.Nats]: 22,
  [ServiceStackTypes.Kafka]: 23,
  [ServiceStackTypes.ObjectStorage]: 24,
  [ServiceStackTypes.SharedStorage]: 25,
};

export const SERVICE_STACK_CATEGORY_TEXT_MAP = {
  [ServiceStackTypes.Elasticsearch]: ServiceStackTextCategories.SearchEngine,
  [ServiceStackTypes.Redis]: ServiceStackTextCategories.Database,
  [ServiceStackTypes.Valkey]: ServiceStackTextCategories.Database,
  [ServiceStackTypes.Qdrant]: ServiceStackTextCategories.Database,
  [ServiceStackTypes.Nats]: ServiceStackTextCategories.Messaging,
  [ServiceStackTypes.Kafka]: ServiceStackTextCategories.Messaging,
  [ServiceStackTypes.MariaDb]: ServiceStackTextCategories.Database,
  [ServiceStackTypes.MongoDb]: ServiceStackTextCategories.Database,
  [ServiceStackTypes.Postgres]: ServiceStackTextCategories.Database,
  [ServiceStackTypes.RabbitMq]: ServiceStackTextCategories.Messaging,
  [ServiceStackTypes.PhpNginx]: ServiceStackTextCategories.PhpWebserver,
  [ServiceStackTypes.PhpApache]: ServiceStackTextCategories.PhpWebserver,
  [ServiceStackTypes.Nginx]: ServiceStackTextCategories.Webserver,
  [ServiceStackTypes.Static]: ServiceStackTextCategories.Static,
  [ServiceStackTypes.NodeJs]: ServiceStackTextCategories.RuntimeEnvironment,
  [ServiceStackTypes.Bun]: ServiceStackTextCategories.RuntimeEnvironment,
  [ServiceStackTypes.Deno]: ServiceStackTextCategories.RuntimeEnvironment,
  [ServiceStackTypes.Java]: ServiceStackTextCategories.RuntimeEnvironment,
  [ServiceStackTypes.Golang]: ServiceStackTextCategories.RuntimeEnvironment,
  [ServiceStackTypes.DotNet]:ServiceStackTextCategories.RuntimeEnvironment,
  [ServiceStackTypes.Rust]: ServiceStackTextCategories.RuntimeEnvironment,
  [ServiceStackTypes.Python]:ServiceStackTextCategories.RuntimeEnvironment,
  [ServiceStackTypes.Ubuntu]: ServiceStackTextCategories.LinuxContainer,
  [ServiceStackTypes.Alpine]: ServiceStackTextCategories.LinuxContainer,
  [ServiceStackTypes.ObjectStorage]: ServiceStackTextCategories.Storage,
  [ServiceStackTypes.SharedStorage]: ServiceStackTextCategories.Storage
};

export const SERVICE_STACK_NAME_TEXT_MAP = {
  [ServiceStackTypes.Redis]: 'KeyDB'
};

export const SERVICE_STACK_NEXT_NAME_TEXT_MAP = {
  [ServiceStackTypes.Redis]: 'keydb'
};
