export const FEATURE_NAME = '@zerops/zerops/feature/import-export-dialog';
export const DEFAULT_PROJECT_YAML = `project:
  name:

services:
  - hostname:
    type:
`;
export const DEFAULT_SERVICE_YAML = `# list all the services as array
services:
  - hostname:
    type:
`;
